@import "../imports.scss";

$text__color: #fff;
$svg__color: #fff;
$color__main: $color__beta;
$color__second: #0023bc;
$color__third: #000;
$color__coookie--disactive: #fff;
$color__coookie--active: #f7567c;

.cookies-button {
    position: fixed;
    left: 0;
    bottom: 0;
    z-index: 9999;
    border: none;
    background-color: $color__main;
    width: sizer(60);
    height: sizer(60);
    padding: sizer(10);
    box-shadow: 0 0 sizer(10) rgba($color: #000000, $alpha: 0.4) !important;
    transition: transform 0.4s ease-out;

    &.hidden {
        transform: translate(-150%);
    }

    img,
    svg {
        width: 100%;
        height: 100%;
        fill: $svg__color;
        object-fit: contain;
        object-position: center;
        transition: transform 0.2s ease-out;
    }

    &:hover,
    &:focus,
    &:active {
        img {
            transform: scale(1.1);
        }
    }
}

.cookies {
    position: fixed;
    bottom: 0;
    left: 5%;
    z-index: 9999;
    transition: transform 0.5s ease-out;
    transform: translate(0, 0);
    color: $text__color;

    @include respond-to("-screen-md") {
        left: sizer(1);
    }

    &.hidden {
        transform: translate(0, 100%);
    }

    &__text {
        &--alfa {
            font-size: text-sizer(14);
            letter-spacing: 0;
            line-height: 1.286em;
            font-weight: $weight__regular;

            @include respond-to("-screen-xs") {
                font-size: text-sizer(12);
            }
        }

        &--beta {
            font-size: text-sizer(20);
            font-weight: $weight__bold;
            letter-spacing: 0;
            margin-left: sizer(18);

            @include respond-to("-screen-sm") {
                font-size: text-sizer(18);
            }

            @include respond-to("-screen-xs") {
                font-size: text-sizer(16);
            }
        }

        &--gamma {
            font-size: text-sizer(14);
            letter-spacing: 0;
            line-height: 1.286em;
            font-weight: $weight__bold;

            @include respond-to("-screen-sm") {
                font-size: text-sizer(13);
            }
        }

        &--delta {
            font-size: text-sizer(14);
            letter-spacing: 0;
            line-height: 1.286em;
        }

        &--epsilon {
            font-size: text-sizer(10);
            letter-spacing: 0;
            font-weight: $weight__bold;
        }

        &--zeta {
            font-size: text-sizer(10);
            font-weight: $weight__light;
            letter-spacing: 0;
        }

        &--eta {
            font-size: text-sizer(18);
            letter-spacing: 0;
            font-weight: $weight__bold;

            @include respond-to("-screen-sm") {
                font-size: text-sizer(16);
            }

            @include respond-to("-screen-xs") {
                font-size: text-sizer(14);
            }
        }

        &--theta {
            font-size: text-sizer(14);
            letter-spacing: 0;
            line-height: 1.286em;
            font-weight: $weight__regular;

            @include respond-to("-screen-xs") {
                font-size: text-sizer(12);
            }
        }
    }

    .top {
        &__close {
            position: relative;
            background: transparent;
            background-repeat: no-repeat;
            border: none;
            cursor: pointer;
            outline: none;
            display: block;
            padding: 0;
            height: sizer(25);
            width: sizer(25);
            transform: translate(50%, -100%);

            @include respond-to("-screen-xs") {
                transform: translate(0, -50%);
            }

            &:hover,
            &:focus,
            &:active {

                &::before,
                &::after {
                    background-color: $color__second;
                }
            }

            &::before,
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform-origin: center;
                width: sizer(20);
                height: sizer(4);
                background-color: $text__color;
                transition: all 0.3s ease-out;
                border-radius: sizer(2);
            }

            &::before {
                transform: translate(-50%, -50%) rotate(45deg);
            }

            &::after {
                transform: translate(-50%, -50%) rotate(-45deg);
            }
        }
    }

    &__line {
        position: relative;
        padding: sizer(1.2) 0;

        &::after {
            content: "";
            height: sizer(1);
            width: calc(100% + #{sizer(24)});
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
            background-color: $text__color;
        }

        &::before {
            content: "";
            height: sizer(1);
            width: calc(100% + #{sizer(24)});
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%);
            background-color: $text__color;
        }

        &.not-top {
            padding-top: 0;

            &::before {
                content: none;
            }
        }

        &.item__text {
            &::after {
                content: none;
            }
        }
    }

    &__buttons {
        display: grid;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        column-gap: sizer(12);
        row-gap: sizer(10);
        padding: 0 sizer(12);
        margin-top: sizer(22);
        position: relative;

        &.line {
            &::before {
                content: "";
                height: sizer(1);
                width: calc(100% + #{sizer(24)});
                position: absolute;
                top: sizer(-22);
                left: 50%;
                transform: translate(-50%);
                background-color: $text__color;
            }
        }
    }

    &__button {
        background-color: transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        outline: none;

        background-color: $color__second;
        padding: sizer(8) 0;
        width: calc(50% - #{sizer(6)});
        @extend .cookies__text--gamma;

        @include respond-to("-screen-xs") {
            width: 100%;
        }

        &:hover,
        &:focus,
        &:active {
            color: $text__color;
        }
    }

    &__arrow {
        position: relative;
        background: transparent;
        background-repeat: no-repeat;
        border: none;
        cursor: pointer;
        outline: none;
        padding: 0;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(0, -50%);

        &::after {
            content: "";
            position: relative;
            display: block;
            border: sizer(3) solid $text__color;
            border-top: none;
            border-left: none;
            width: sizer(12);
            height: sizer(12);
            transition: all 0.3s ease-out;
            transform: rotate(45deg) scale(1, 1);
        }

        &:hover,
        &:focus,
        &:focus-within,
        &:active {
            &::after {
                border-color: $color__second;
            }
        }

        &[aria-expanded="true"] {
            &::after {
                transform: rotate(45deg) scale(-1, -1);
            }
        }
    }

    &__container {
        padding: sizer(32);
        padding-top: sizer(12);

        @include respond-to("-screen-xs") {
            padding: sizer(20) sizer(20);
        }

        .container {
            &__text {
                // padding: sizer(10);
            }
        }
    }

    &__general {
        width: sizer(475);
        // border-radius: sizer(30);
        max-height: calc(100vh - 90px);
        height: max-content;
        max-width: calc(100vw - 20px);
        background-color: $color__main;
        box-shadow: 0 0 sizer(20) sizer(1) rgba($color: $color__third, $alpha: 0.25);
        transition: transform 0.5s ease-out;
        position: absolute;
        bottom: sizer(20);
        left: 0;
        z-index: 201;
        overflow: auto;

        &.hidden {
            transform: translate(0, 130%);
        }

        .general {
            &__top {
                background-color: $color__main;
                width: 100%;
                padding: sizer(30) sizer(32) 0 sizer(32);
                padding-bottom: 0;

                @include respond-to("-screen-xs") {
                    padding: sizer(20) sizer(20);
                    padding-bottom: 0;
                }

                .top {
                    &__icon {
                        display: block;
                        width: sizer(66);
                        height: sizer(58);
                        min-width: sizer(66);

                        // @include respond-to("-screen-xs") {
                        //     width: sizer(40);
                        //     height: sizer(40);
                        // }

                        // @include respond-to("-screen-xs") {
                        //     width: sizer(35);
                        //     height: sizer(35);
                        // }

                        svg,
                        img {
                            fill: $svg__color;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    &__header {}
                }
            }
        }
    }

    &__settings {
        width: sizer(475);
        // border-radius: sizer(30);
        background-color: $color__main;
        max-height: calc(100vh - 90px);
        box-shadow: 0 0 sizer(20) sizer(1) rgba($color: $color__third, $alpha: 0.25);
        transition: transform 0.5s ease-out, opacity 0.5s ease-out;
        position: relative;
        z-index: 202;
        overflow: hidden;
        overflow-y: auto;
        margin-bottom: sizer(20);
        padding-right: sizer(20);

        @include respond-to("-screen-xs") {
            padding-right: 0;
        }

        &.hidden {
            transform: translate(0, 100%);
            opacity: 0;
            z-index: -1;
        }

        @include respond-to("-screen-md") {
            width: calc(100vw - 20px);
        }

        .cookies__buttons {
            position: sticky;
            left: 0;
            bottom: 0;
            padding: sizer(28) sizer(44);
            margin-top: 0;
            width: 100%;
            background-color: $color__main;

            &::before {
                content: "";
                height: sizer(1);
                width: calc(100% - #{sizer(36)});
                position: absolute;
                top: 0;
                left: 50%;
                transform: translate(-50%);
                background-color: $text__color;
            }
        }

        .settings {
            &__top {
                background-color: $color__main;
                width: 100%;
                padding: sizer(24) sizer(32);
                padding-bottom: sizer(20);
                position: sticky;
                left: 0;
                top: 0;
                z-index: 100;

                @include respond-to("-screen-xs") {
                    padding: sizer(20) sizer(20);
                    padding-bottom: 0;
                }

                .top {
                    &__icon {
                        display: block;
                        width: sizer(66);
                        height: sizer(58);
                        min-width: sizer(66);

                        // @include respond-to("-screen-xs") {
                        //     width: sizer(40);
                        //     height: sizer(40);
                        // }

                        // @include respond-to("-screen-xs") {
                        //     width: sizer(35);
                        //     height: sizer(35);
                        // }

                        svg,
                        img {
                            fill: $svg__color;
                            width: 100%;
                            height: 100%;
                        }
                    }

                    &__header {}
                }
            }

            &__container {
                padding: sizer(32);
                padding-top: 0;

                @include respond-to("-screen-xs") {
                    padding: sizer(20) sizer(20);
                }

                .container {
                    &__item {
                        .text {
                            &__cookie {
                                margin-top: sizer(10);
                                padding-top: sizer(10);
                                padding-bottom: sizer(10);
                                @extend .cookies__text--delta;

                                &::before {
                                    content: "";
                                    width: 90%;
                                    height: sizer(1);
                                    position: absolute;
                                    left: sizer(-12);
                                    top: 0;
                                    background-color: $text__color;
                                }

                                &:first-child {
                                    margin-top: 0;

                                    &::before {
                                        content: none;
                                    }
                                }

                                .cookie {
                                    &__header {
                                        margin-top: sizer(5);
                                        @extend .cookies__text--epsilon;
                                    }

                                    &__text {
                                        @extend .cookies__text--zeta;
                                    }

                                    &__info {
                                        padding-right: sizer(20);
                                        width: 50%;
                                        order: 0;

                                        @include respond-to("-screen-sm") {
                                            width: 100%;
                                            padding-right: 0;
                                        }

                                        &--target {
                                            width: 100%;
                                            order: 1;
                                        }

                                        .info {
                                            &__item {
                                                width: 50%;
                                                word-break: break-word;
                                                padding-right: sizer(4);

                                                @include respond-to("-screen-sm") {
                                                    width: 100%;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .item {
                            &__header {
                                padding-bottom: sizer(10);
                                margin-top: sizer(12);
                                padding-top: sizer(10);
                                position: relative;
                                @extend .cookies__text--eta;
                                justify-content: flex-start !important;

                                .cookies__arrow {
                                    justify-self: flex-end !important;
                                }

                                .text {
                                    &__checkbox {
                                        opacity: 0;
                                        width: 0;
                                        height: 0;
                                        position: absolute;
                                        left: 0;
                                        top: 0;

                                        &:hover~.text__label,
                                        &:focus~.text__label,
                                        &:active~.text__label {
                                            &::before {
                                                box-shadow: sizer(1) sizer(1) sizer(3) $color__third;
                                            }
                                        }

                                        &:checked~.text__label::after {
                                            transform: translate(100%);
                                            background-color: $color__coookie--active;
                                        }

                                        &:disabled~.text__label::after {
                                            transform: translate(100%);
                                            background-color: $color__coookie--active;
                                            filter: grayscale(0.65);
                                        }
                                    }

                                    &__label {
                                        // padding-right: 0;
                                        position: relative;
                                        cursor: pointer;
                                        transition: all 0.2s ease-out;
                                        width: sizer(50);
                                        margin-right: sizer(14);

                                        &:hover,
                                        &:focus,
                                        &:active {
                                            &::before {
                                                box-shadow: sizer(1) sizer(1) sizer(3) $color__third;
                                            }
                                        }

                                        &::before {
                                            content: "";
                                            display: block;
                                            width: 100%;
                                            height: sizer(20);
                                            border: sizer(1) solid $text__color;
                                            border-radius: 0;
                                            transition: all 0.2s ease-out;
                                        }

                                        &::after {
                                            content: "";
                                            position: absolute;
                                            top: sizer(3);
                                            left: sizer(3);
                                            width: sizer(22);
                                            height: sizer(14);
                                            border-radius: sizer(3);
                                            background-color: $color__coookie--disactive;
                                            transition: all 0.2s ease-in-out;
                                        }
                                    }
                                }
                            }

                            &__text {
                                padding-bottom: sizer(12);
                                padding-top: sizer(12);
                                @extend .cookies__text--theta;
                            }
                        }
                    }
                }
            }
        }
    }

    .simplebar-track {
        background-color: rgba($color: $text__color, $alpha: 0.4);

        @include respond-to("screen-xs") {
            width: sizer(6) !important;
            transform: translate(sizer(-15), sizer(15));
            overflow: visible !important;
            max-height: calc(100% - #{sizer(30)});
        }

        .simplebar-scrollbar {
            @include respond-to("screen-xs") {
                overflow: visible;
            }

            &::before {
                background-color: $text__color;

                @include respond-to("screen-xs") {
                    position: absolute;
                    left: 50%;
                    top: 0;
                    width: sizer(16);
                    height: 100%;

                    transform: translate(-50%);
                    opacity: 1 !important;
                }
            }
        }
    }
}